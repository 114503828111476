import React from "react";
import { graphql, useStaticQuery } from "gatsby";
import ToolsLayout from "../../../components/toolsLayout";
import SEO from "../../../components/seo";
import PenaltyUnitsCalculator from "../../../components/penaltyUnits/penaltyUnitsCalculator";
import PenaltyUnitCalculatorIllustration from "../../../images/penalty-unit-calculator-illustration.svg";
import Apollo from "../../../components/apollo";
import WithLocation from "../../../components/withLocation";
import { penaltyUnitCalculatorQuery } from "../../../utils/staticQueryFragments";
import { getJurisdictionQueryString, getMaximumPenaltyUnitsQueryString, getFinancialYearQueryString } from "../../../utils/penaltyUnitCalculatorHelper"; 
import PenaltyUnitsCalculatorSkeleton from "../../../components/penaltyUnits/penaltyUnitsCalculatorSkeleton";

const ToolsPenaltyUnitsPage = (props) =>
{
  const { site } = useStaticQuery(
    graphql`
      query
      {
        site
        {
          siteMetadata
          {
            tools
            {
              id
              name
              description
              link
            }
          }
        }
      }
    `
  );

  const tool = site.siteMetadata.tools.filter(t => t.id === 1)[0];

  return (
    <ToolsLayout
      show = { tool && tool !== null }
      name = { tool.name }
      description = { tool.description }
      icon = { PenaltyUnitCalculatorIllustration }
      goBackDestination = "/tools"
      goBackText = "← Back to Tools Menu"
    >
      <SEO
        keywords = { [`LawChest`, `penalty`, `unit`, `calculator`] }
        title = "Penalty Unit Calculator"
      />
      <section
        id = "utilitySection"
      >
        <Apollo
          propsToInsert =
          {
            (queryResults) => (
              { jurisdictionsQueryResults: queryResults }
            )
          }
          query = { penaltyUnitCalculatorQuery }
          queryOptions = { { notifyOnNetworkStatusChange: true } }
          loadingComponent = { PenaltyUnitsCalculatorSkeleton }
        >
          <PenaltyUnitsCalculator
            jurisdictionQueryString = { getJurisdictionQueryString(props.search.j) }
            maximumPenaltyUnitsQueryString = { getMaximumPenaltyUnitsQueryString(props.search.mpu) }
            financialYearQueryString = { getFinancialYearQueryString(props.search.fy) }
          />
        </Apollo>
      </section>
    </ToolsLayout>
  );
}

export default WithLocation(ToolsPenaltyUnitsPage);
